import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL, API_URL } from "../../utils/config";
import {
  getSubCategories,
  getSubChildCategories,
  getParentCategories,
  postProduct,
  postProductAction,editpostProduct,
} from "../../slices/addProductSlice";

import close from "../../img/close.png";
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import axios from "axios";
import { getSignInResponse } from "../../utils/authUtil";
import Compressor from "compressorjs";
import Loading from "../include/Loading";
import { useNavigate, Link } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';

function AddProduct(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getParentCategories());
  }, [dispatch]);
 
  const categories = useSelector((state) => state.addProduct.categories);
  

  const globalLocation = useSelector((state) => state.location.globalLocation);

  const postedData = useSelector((state) => state.addProduct.postedData);
  // console.log('rammmmmmmjiiii44444===>', postedData);

  const isLoading = useSelector((state) => state.addProduct.isLoading);

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const mode = props.edit;

  const [imagearr, setImagearr] = useState([]);

  const [productid, setProductid] = useState('');

  const [producturl, setProducturl] = useState('');

  const [subcategorieslist, setSubcategorieslist] = useState([]);

  const [subchildcategorieslist, setSubchildcategorieslist] = useState([]);


  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [activeCategoryName, setActiveCategoryName] = useState(null);
  const [activeChildCategoryId, setActiveChildCategoryId] = useState(null);
  const [activeChildCategoryName, setActiveChildCategoryName] = useState(null);
  const [activeParentCategoryId, setActiveParentCategoryId] = useState(null);
  const [activeParentCategoryName, setActiveParentCategoryName] = useState(null);
  const [subcategoriesLoaded, setSubcategoriesLoaded] = useState(false);
  const [subchildcategoriesLoaded, setSubchildcategoriesLoaded] = useState(false);
  const [editstep, setEditstep] = useState('');

  const initialState = {
    title: "",
    description: "",
    offerType: "1",
    remarks: "",
    price: "",
    desiredSwops: "",
    rootCategoryId: '',
    categoryId: 22,
    subCategoryId: 73,
    swopByCash: 0,
  };


  useEffect(() => {
    if (postedData && postedData.posted_product_id) {
      let data = {
        action: "active",
        posted_product_id: postedData.posted_product_id,
      };
      dispatch(postProductAction(data));
      window.openCongPop();
      
  
    }
   
  }, [postedData]);


  useEffect(() => {

    if (document.getElementById("ContinueStep")) {
      const continuestep = document.getElementById("ContinueStep");
      const prevstep = document.getElementById("prevstep");
      const postswop = document.getElementById("postswop");
      const progressbar = document.getElementById("progressbar");
      const steps = document.querySelectorAll(".commanstep");
      const contentBlocks = document.querySelectorAll(".stepcontainer > .comman-data");

     
      console.log('wwwwwwww33333',steps);
      let activeIndex = 0;
  
      const setActive = (toggle, index) => {
        progressbar.style.width = `${index * (100 / steps.length)}%`;
        steps[index].classList.toggle("active", toggle);
  
        // Show the corresponding content block for the active step
        contentBlocks.forEach((block, blockIndex) => {
          block.classList.toggle("active", blockIndex === index);
        });
      };
  
      const updateButtonStates = () => {
        
        // prevstep.disabled = activeIndex === 0;
        if(activeIndex === 0){
          prevstep.style.display = "none";
        } else {
          prevstep.style.display = "block"; 
        }
        continuestep.style.display = (activeIndex < steps.length - 1) ? "block" : "none";
       // prevstep.style.display = (activeIndex < steps.length - 1) ? "block" : "none";
        postswop.style.display = "none"; // Hide the Post Swop button
      };
  
      const markStepAsDone = (index) => {
        steps[index].classList.add("done");
      };
     
      continuestep.addEventListener("click", () => {
      
       
        if(activeIndex ==1 &&!$('#categorycountid').html()){
          Swal.fire({  
            title: 'Alert',  
            text: 'Please select any category !',
            icon: 'error'
          }); 
          return false;
        } 

        // if(activeIndex ==2 &&!$('#categorychildcountid').html()){
        //   Swal.fire({  
        //     title: 'Alert',  
        //     text: 'Please select any child category !',
        //     icon: 'error'
        //   }); 
        //   return false;
        // } 
        
        if(activeIndex ==3 && !$('#imagecountid').html()){
          Swal.fire({  
            title: 'Alert',  
            text: 'Please choose any image !',
            icon: 'error'
          }); 
          return false;
        }
        markStepAsDone(activeIndex); // Mark the current step as done
        setActive(false, activeIndex); // Deactivate the current step
        activeIndex += 1;
        if (activeIndex === steps.length) {
          activeIndex = steps.length - 1;
        }
        setActive(true, activeIndex); // Activate the next step
        updateButtonStates();
        console.log('11111111111111',activeIndex);
        console.log('1111111111111111',steps.length);
        if (activeIndex === steps.length - 1) {
          continuestep.style.display = "none"; // Hide the Continue button
          postswop.style.display = "block"; // Show the Post Swop button
          progressbar.style.width = "100%";
          prevstep.style.display = "block";
        }
      });
  
      prevstep.addEventListener("click", () => {
        markStepAsDone(activeIndex); // Mark the current step as done
        setActive(false, activeIndex); // Deactivate the current step
        activeIndex -= 1;
        if (activeIndex < 0) {
          activeIndex = 0;
        }
        setActive(true, activeIndex); // Activate the previous step
        updateButtonStates();
      });
  
      // Initialize the state
      setActive(true, activeIndex);
      updateButtonStates();
  
      // Add event listeners to step elements
      steps.forEach((step, index) => {
        step.addEventListener('click', () => {
          markStepAsDone(activeIndex); // Mark the current step as done
          activeIndex = index; // Update the active step index
          setActive(true, index);
          updateButtonStates();
        });
      });
    }


    const uploadInputs = document.querySelectorAll('.upload__inputfile');
  const categoryList = document.querySelector('.category-list2');

  

  },[]);



 

  const handleCategoryClick = (categoryId) => {
    
  
    const clickedCategory = subcategorieslist.find(
      (category) => category.id == categoryId
    );
  
    if (clickedCategory) {
      $('#subcat_' + categoryId).addClass('active');
      setActiveCategoryId(categoryId);
      setPostData((prevState) => ({ ...prevState, categoryId: categoryId }));
      getsubchildcategoriesbyparent(categoryId);
      setActiveCategoryName(clickedCategory.name);
      setActiveChildCategoryId('');
      setActiveChildCategoryName('');
      
    } else {
      console.error(`Category with categoryId ${categoryId} not found.`);
      // Handle this scenario according to your application's logic
    }
  };


  const handleChildCategoryClick = (categoryId) => {
    
  
    const clickedCategory = subchildcategorieslist.find(
      (category) => category.id == categoryId
    );
  
    if (clickedCategory) {
      $('#subchildcat_' + categoryId).addClass('active');
      setActiveChildCategoryId(categoryId);
      setPostData((prevState) => ({ ...prevState, subCategoryId: categoryId }));
      
      setActiveChildCategoryName(clickedCategory.name);
      
    } else {
      console.error(`Category with categoryId ${categoryId} not found.`);
      // Handle this scenario according to your application's logic
    }
  };

const getsubcategoriesbyparent = async (id) => {
  console.log('111111111111', id);
  try {
    const subcategories = await dispatch(getSubCategories(id));
    console.log('Subcategories:', subcategories.payload.data.list);
    setSubcategorieslist(subcategories.payload.data.list);
    setSubcategoriesLoaded(true); // Mark that subcategories have been loaded
  } catch (error) {
    console.error('Error fetching subcategories:', error);
  }
};

const getsubchildcategoriesbyparent = async (id) => {
  console.log('2222222222', id);
  try {
    const subcategories = await dispatch(getSubChildCategories(id));
    
    setSubchildcategorieslist(subcategories.payload.data.list);
    
    setSubchildcategoriesLoaded(true); // Mark that subcategories have been loaded
  } catch (error) {
    console.error('Error fetching subcategories:', error);
  }
};


const handleParentCategoryClick = (categoryId) => {
  const clickedCategory = categories.find(
    (category) => category.id == categoryId
  );
  if (clickedCategory) {
    setActiveParentCategoryId(categoryId);
    setActiveParentCategoryName(clickedCategory.name);
    setPostData((prevState) => ({ ...prevState, rootCategoryId: categoryId }));
         getsubcategoriesbyparent(categoryId);
         setActiveChildCategoryId('');
         setActiveChildCategoryName('');
         setActiveCategoryId('');
         setActiveCategoryName('');
    
  } else {
    console.error(`Category with categoryId ${categoryId} not found.`);
    // Handle this scenario according to your application's logic
  }


};






const [postData, setPostData] = useState({
  title: "",
  description: "",
  offerType: "1",
  remarks: "",
  price: "",
  desiredSwops: "",
  rootCategoryId: '',
  categoryId: 22,
  subCategoryId: 73,
  swopByCash: 0,
});

useEffect(() => {
  if (props.item && Object.keys(props.item).length !== 0) {
    const {
      title,
      description,
      offer_type,
      remarks,
      price,
      desired_swops,
      rootCategoryId,
      category_id,
      subCategoryId,
      posted_product_id,
    } = props.item;

    setPostData({
      ...postData,
      title: title || "",
      description: description || "",
      offerType: offer_type || "1",
      remarks: remarks || "",
      price: price || "",
      desiredSwops: desired_swops || "",
      rootCategoryId: rootCategoryId || "",
      categoryId: category_id || 22,
      subCategoryId : subCategoryId || 73,
      posted_product_id :posted_product_id,
    });
    
    setActiveCategoryId(props.item.category_id);
    setActiveParentCategoryId(props.item.rootCategoryId);
    setProductid(props.item.id);
   
    setActiveChildCategoryId(props.item.subCategoryId);
    setImageUrls(props.item.gallery);
    setEditstep('true');
    //addImagesToGallery(props.item.gallery);
    getsubcategoriesbyparent(props.item.rootCategoryId);
    getsubchildcategoriesbyparent(props.item.category_id);
  // $('.list-of-sub-category').removeClass('d-none');
  // $('.list-ofmain-cat').addClass('d-none')
  }
  
}, [props.item]);

useEffect(() => {
 
  if (subcategoriesLoaded && props.item && props.item.category_id) {
    const clickedCategory = subcategorieslist.find(
      (category) => category.id == props.item.category_id
    );
    setActiveCategoryName(clickedCategory.name);
    if (subchildcategorieslist.length > 0) {
    const clickedchildCategory = subchildcategorieslist.find(
      (category) => category.id == props.item.subCategoryId
    );
     setActiveChildCategoryName(clickedchildCategory.name);
  }
   
  }
  
}, [subcategoriesLoaded, props.item]);


useEffect(() => {
  console.log('categoriescategories', categories);
  if (props.item && props.item.category_id) {
  const selectedCategory = categories.find(
    (category) => category.id == props.item.rootCategoryId
  );
if (selectedCategory) {
    setActiveParentCategoryName(selectedCategory.name);
  }
}
}, [props.item]);


  //   console.log("🚀 ~ file: AddProduct.js:35 ~ AddProduct ~ postData:", postData);

  const handleOfferChange = (e) => {
    
    setPostData((prevState) => ({
      ...prevState,
      offerType: e.target.value,
    }));
  };

  const handleTagChange = (e) => {
    let data = e.detail.tagify.getCleanValue();
    let resultString = data.map((obj) => obj.value).join(",");
    setPostData((prevState) => ({
      ...prevState,
      desiredSwops: resultString,
    }));
  };

  const handlePostSwop = () => {

    // Validation logic
    const errors = {};

    // Check if title is empty
    if (!postData.title.trim()) {
      errors.title = "Title is required";
      alert("title is required");
    }

    // Check if description is empty
    else if (!postData.description.trim()) {
      errors.description = "Description is required";
      alert("description is required");
    }

    // Check if remark is empty
    else if (!postData.remarks.trim()) {
      errors.remarks = "Condition/Remarks is required";
      alert("reamrks is required");
    }

    // Check based on offer type
    else if (postData.offerType === "1") {
      // Offer type is Swop, so desiredSwops should not be empty
      if (!postData.desiredSwops.trim()) {
        errors.desiredSwops = "Desired Swops is required";
        alert("desired swops is required");
      }
    } else if (postData.offerType === "2") {
      // Offer type is Sell, so price should not be empty
      if (!postData.price) {
        errors.price = "Price is required";
        alert("price is required");
      }
    } else if (postData.offerType === "3") {
      // Offer type is Swop & Sell, so both desiredSwops and price should not be empty
      if (!postData.desiredSwops.trim()) {
        errors.desiredSwops = "Desired Swops is required";
        alert("desired swops is required");
      } else if (!postData.price.trim()) {
        errors.price = "Price is required";
        alert("price is required");
      }
    }

    // Set the error messages
    setErrorMessages(errors);

    // If there are no errors, dispatch the action
    if (Object.keys(errors).length === 0) {
      const result = {};
      console.log('sssss3333333',imageUrls)
      for (let i = 0; i < imageUrls.length; i++) {
        result[`image_paths[${i}]`] = imageUrls[i];
      }

      let location = {
        city_name: globalLocation.city,
        lat: globalLocation.latitude,
        lng: globalLocation.longitude,
        location_address: globalLocation.city,
      };

      let finalData = { ...postData, ...result, ...location };

      if(mode == 'yes'){
        
        
        //dispatch(editpostProduct(finalData));
        dispatch(editpostProduct(finalData)).then(returnData => {
          console.log('Return data from postProduct:', returnData.payload.data);
          setProducturl(returnData.payload.data.slug)
        }).catch(error => {
          console.error('Error posting product:', error);
          // Handle error
        });
      } else {
       
        //dispatch(postProduct(finalData));
        dispatch(postProduct(finalData)).then(returnData => {
          console.log('Return data from postProduct:', returnData.payload.data);
          setProducturl(returnData.payload.data.slug)
        }).catch(error => {
          console.error('Error posting product:', error);
          // Handle error
        });
        
      }
      console.log("here", finalData);
    }
  };

  const [imageUrls, setImageUrls] = useState([]);
  // console.log(
  //   "🚀 ~ file: AddProduct.js:66 ~ AddProduct ~ imageUrls:",
  //   imageUrls
  // );
  const [isLoaded, setIsLoaded] = useState(true);

  const uploadImage = async (file,setIsLoading) => {
    try {
      setIsLocalLoading(true);
      const compressedResult = await compressImage(file);
      const formData = new FormData();
      formData.append("images[0]", compressedResult);
      let userData = getSignInResponse();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "x_rest_username": "admin@restuser",
          "x_rest_password": "admin@Access",
          "x_rest_version": "v1",
          // "Latitude": password.latitude,
          // "Longitude": password.longitude,
          "Authorization": "Bearer " + userData.auth_token,
          "user_id": userData.id,
        },
      };
      const response = await axios.post(
        API_URL + `user/upload-images-s3`,
        formData,
        config
      );

      if (response.status === 200) {
        const responseData = response.data;
        console.log('sssss3333333',responseData.data[0])
        setImageUrls((prevImageUrls) => [
          ...prevImageUrls,
          responseData.data[0],
        ]);
        setIsLocalLoading(false);
      } else {
        // Handle the error here
        console.error("Upload failed");
      }
    } catch (error) {
      setIsLocalLoading(false);
      // Handle any errors that occurred during the process
      console.error("Error:", error);
    }
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.6,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const handleFileChange = (e) => {
    setIsLoaded(false);
    const files = e.target.files;
    
    const uploadPromises = Array.from(files).map((file) => uploadImage(file));
    Promise.all(uploadPromises)
      .then(() => {
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoaded(true);
      });
  };

  const [errorMessages, setErrorMessages] = useState({
    title: "",
    description: "",
    remarks: "",
    desiredSwops: "",
    price: "",
  });

  const handleProductDetail = () => {
    navigate(`/product/${postedData.slug}`);
  };

  const resetState = () => {
    // setPostData(initialState);
    // setImageUrls([]);
    // setActiveCategoryId(null);
    // setActiveCategoryName(null);
    
  };

const redirecturl=(e)=>{
  e.preventDefault();
  // console.log('eeeeeeeeeeeeeeeee22222222222',producturl)
  window.location.href='/detail/'+producturl+'/product';
 // navigate('/detail/'+producturl+'/product');
  document.getElementById('congrulations').classList.remove('open-it');
  document.body.classList.remove('hidden-scroll');
  resetState(); // Reset the state
}

const handleContinueSwop = () => {
  // Find the button with the ID 'ContinueStep'
  const continueButton = document.getElementById("ContinueStep");

  // Check if the button exists
  if (continueButton) {
    // Trigger the click event on the button
    continueButton.click();
  } else {
    console.log("Button with ID 'ContinueStep' not found.");
  }
};


  return (
    <>
     {isLocalLoading == true ? <Loading loading={isLocalLoading} /> : <Loading loading={isLoading}/>}

     <div class="comman-model comman-side-popup lg-side-popup create-account-model" id="AddProduct">
        <div className="comman-popup-inner">
          <div className="top-model-header">
            <div className="pop-top-title">
              <h2>Add Product</h2>
            </div>
            <a href="javascript:void(0);" className="close_panel">
              <i>
                <img src={close} alt="" />
              </i>
            </a>
          </div>
          <div className="popup-model-body">
            <form>
              <div className="steps-panel-top">
                <div className="progressbar" id="progressbar"></div>
                <ul>
                  <li>
                    <div className="commanstep step01">
                      <span className="cricle">1</span>
                      <span>Choose A Category</span>
                      <span className="valuecheckpt">
      {activeParentCategoryName && <>{activeParentCategoryName}</>}
      {activeParentCategoryName && activeCategoryName && " / "}
      {activeCategoryName && <>{activeCategoryName}</>}
      {activeCategoryName && activeChildCategoryName && " / "}
      {activeChildCategoryName && <>{activeChildCategoryName}</>}
    </span>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="commanstep step02">
                      <span className="cricle">1</span>
                      <span>Choose A Sub Category</span>
                      <span className="valuecheckpt" id="categorycountid">{activeCategoryName}</span>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="commanstep step03">
                      <span className="cricle">1</span>
                      <span>Choose A Sub Category</span>
                      <span className="valuecheckpt" id="categorychildcountid">{activeChildCategoryName}</span>
                    </div>
                  </li>
                  <li>
                    <div className="commanstep step04">
                      <span className="cricle">2</span>
                      <span>Upload Product Images</span>
                      <span className="valuecheckpt" id="imagecountid">{imageUrls.length ? imageUrls.length : ""}</span>
                    </div>
                  </li>
                  <li>
                    <div className="commanstep step05">
                      <span className="cricle">3</span>
                      <span>Product Details</span>
                      <span className="valuecheckpt">&nbsp;</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="stepcontainer">
               
                <div class="comman-data step-data01 active">
                        <div class="multi-cat-box-wrap">
                           <ul class="list-ofmain-cat">
                           {categories &&
                           [...categories]
                           .sort((a, b) => a.order_number - b.order_number)
                      .map((category) => (
                        <li
                        key={category.id}
                        onClick={() => handleParentCategoryClick(category.id)}
                        
                          >
                                 <div className={activeParentCategoryId === category.id ? 'multi-box-root active' : 'multi-box-root'}>
                                    <div class="main-card-box">
                                       <i><img src={`${API_BASE_URL}${category.icon}`} alt=""/></i>
                                       <span class="cat-title">{category.name} </span>
                                    </div>
                                 </div>
                              </li>
                              ))}
                        
                           </ul>
                           <div class="list-of-sub-category d-none"> 
                            
                           </div>
                        </div>
                     </div>
                     <div class="comman-data step-data02">
                        <div class="multi-cat-box-wrap">
                        
                           <div class="list-of-sub-category" id="subcategory"> 
                              <ul>
                              {subcategorieslist &&
                                   [...subcategorieslist]
                                   .sort((a, b) => a.order_number - b.order_number)
                                   .map((category) => (
                                    <li
                                    key={category.id}
                                    onClick={() => handleCategoryClick(category.id)}
                                    className={activeCategoryId === category.id ? 'active' : ''}
                                    id={"subcat_" + category.id}
                                  >
                                    <a href="#">{category.name} <i class="arrow-icon">
                                    <img class="default-icon" src="img/default-arrow.svg" alt=""/>
                                    <img class="hover-icon" src="img/default-hover-arrow.svg" alt=""/>
                                    </i> </a>
                                 </li>
                                  ))}

                              </ul>
                           </div>

                          
                        </div>
                     </div>
                     
                     <div class="comman-data step-data03">
                        <div class="multi-cat-box-wrap">
                        
                           

                           <div class="list-of-sub-category" id="childcategory" > 
                                            <ul>
                        {subchildcategorieslist && subchildcategorieslist.length > 0 ? (
                        [...subchildcategorieslist]
                        .sort((a, b) => a.order_number - b.order_number)
                        .map((category) => (
                        <li
                        key={category.id}
                        onClick={() => handleChildCategoryClick(category.id)}
                        className={activeChildCategoryId == category.id ? 'active' : ''}
                        id={"subchildcat_" + category.id}
                        >
                        <a href="#">
                          {category.name} 
                          <i className="arrow-icon">
                            <img className="default-icon" src="img/default-arrow.svg" alt=""/>
                            <img className="hover-icon" src="img/default-hover-arrow.svg" alt=""/>
                          </i>
                        </a>
                        </li>
                        ))
                        ) : (
                        <><p>No subcategories found for {activeCategoryName}.
                           <a  className="continuestep subchildcat" onClick={() => handleContinueSwop()}>
                           Continue
                            </a></p>
                        </>
                        )}
                        </ul>

                           </div>
                        </div>
                     </div>
                <div className="comman-data step-data04 ">
                  <ul className="category-list productuploded category-list2">
                    <li>
                      <div className="upload__btn-box">
                        <label className="upload__btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="79"
                            height="79"
                            viewBox="0 0 79 79"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_178_1406)">
                              <path
                                d="M39.3301 16.5046V62.1556"
                                stroke="white"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16.5044 39.3301H62.1554"
                                stroke="white"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_178_1406">
                                <rect
                                  width="78.2589"
                                  height="78.2589"
                                  fill="white"
                                  transform="translate(0.200684 0.200684)"
                                />
                              </clipPath>
                            </defs>
                          </svg>

                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            data-max_length="20"
                            className="upload__inputfile"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                    </li>

                       {/* Display uploaded images */}
                       {imageUrls.map((imageUrl, index) => (
        <li key={index}>
          <div className="upload__img-box" style={{ backgroundImage: `url(${imageUrl})` }}>
            {/* Close button */}
            <div className="upload__img-close" onClick={() => setImageUrls(prevImageUrls => prevImageUrls.filter((_, i) => i !== index))}></div>
          </div>
        </li>
      ))}
                  </ul>
                </div>
                <div className="comman-data step-data05">
                  <div className="postdecriptions">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Post Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Post Title"
                            value={postData.title}
                            onChange={(e) =>
                              setPostData((prevState) => ({
                                ...prevState,
                                title: e.target.value,
                              }))
                            }
                          />
                          <div className="the-count-time">
                            <span className="current">0</span>
                            <span className="maximum">/500</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Description"
                            value={postData.description}
                            onChange={(e) =>
                              setPostData((prevState) => ({
                                ...prevState,
                                description: e.target.value,
                              }))
                            }
                          ></textarea>
                          <div className="the-count-time">
                            <span className="current">0</span>
                            <span className="maximum">/500</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Condition/Remarks</label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Condition/Remarks"
                            value={postData.remarks}
                            onChange={(e) =>
                              setPostData((prevState) => ({
                                ...prevState,
                                remarks: e.target.value,
                              }))
                            }
                          ></textarea>
                          <div className="the-count-time">
                            <span className="current">0</span>
                            <span className="maximum">/500</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Condition/Remarks</label>
                          <ul className="d-flex remarkswrap">
                            <li>
                              <div className="radio-outer">
                                <label>
                                  <input
                                    type="radio"
                                    className="input-radio"
                                    name="condition"
                                    // checked
                                    value="1"
                                    checked={postData.offerType == "1"}
                                    onChange={handleOfferChange}
                                  />{" "}
                                  Swop{" "}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-outer">
                                <label>
                                  <input
                                    type="radio"
                                    className="input-radio"
                                    name="condition"
                                    value="2"
                                    checked={postData.offerType == "2"}
                                    onChange={handleOfferChange}
                                  />{" "}
                                  Sell{" "}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-outer">
                                <label>
                                  <input
                                    type="radio"
                                    className="input-radio"
                                    name="condition"
                                    value="3"
                                    checked={postData.offerType == "3"}
                                    onChange={handleOfferChange}
                                  />{" "}
                                  Swop & Sell{" "}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {postData.offerType != "2" && (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>I'd like to swop for</label>
                            {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Item"
                          /> */}
                            <Tags
                              className="form-control"
                              value={postData.desiredSwops}
                              onChange={handleTagChange}
                            />
                          </div>
                        </div>
                      )}
                      {postData.offerType != "1" && (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Price"
                              value={postData.price}
                              onChange={(e) =>
                                setPostData((prevState) => ({
                                  ...prevState,
                                  price: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="model-footer-panel justify-content-end">
         
            <button
              id="prevstep"
              className="btn btn-border  back-step-clk  mr-auto continuestep "
            >
              back
            </button>
         
            <button id="ContinueStep" className="btn btn-primary  continuestep">
            Continue
          </button>
          
            <button
              id="postswop"
              className="btn btn-primary continuestep"
              // data-popup-id="congrulations"
              // style={{ display: "none" }}
              onClick={() => handlePostSwop()}
            >
              Post Swop
            </button>
          </div>
        </div>
        <div className="shadowpt clearfix"></div>
      </div>

      <div className="comman-model congrulation-model" id="congrulations">
        <div className="comman-popup-inner">
          <a
            href="javascript:void(0);"
            className="close_panel"
            onClick={() => window.location.reload()}
          >
            <i>
              <img src={close} alt="" />
            </i>
          </a>
          <div className="congrulation-top">
            <img src="../../img/spark.jpg" alt="" />
          </div>
          <div className="congrulations-body">
            <h2 className="title-congrate">Congratulation</h2>
            <p>Swop Posted Successfully</p>
            <p>Pending Approval</p>
          </div>
          <div className="congrate-foot">
            <a
               href="/"
              className="btn btn-border"
              // onClick={() => window.location.reload()}
            >
              Back to home
            </a>
            <Link
              // href=""
              // to={producturl}
              className="btn btn-primary"
               onClick={(e) => redirecturl(e)}
            >
              View Detail
            </Link>
          </div>
        </div>
        <div className="shadowpt clearfix"></div>
      </div>
    </>
  );
}

export default AddProduct;
