import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProductDetail,addwishlistItem,addReport,activeProductaction,suggestSwopAction } from "../../slices/productDetailSlice";
import Loading from "../include/Loading";
import Cards from "../cards/Cards";
import Swal from 'sweetalert2';
import Swopsuggestion from "../popups/Swopsuggestion";
import { useNavigate } from "react-router-dom";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignIn/SignUp";
import Slider from "react-slick";
import { API_BASE_URL } from "../../utils/config";
import close from "../../img/close.png";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selected_report_reason, setSelectedreportreason] = useState('');
  const [selected_report_reason_other, setSelectedreportreasonother] = useState('');
  const [reportModalOpened,setreportModalOpened] = useState(false);
  const [posted_product_id,setPostedproductid] = useState('');
  const [result, setResult] = useState(null);
  const [suggestProducts, setsuggestProducts] = useState([]);
  const [suggestModalOpened, setsuggestModalOpened] = useState(false);
  const [suggestModalProductId, setsuggestModalProductId] = useState(0);
  const [shareitem, setShareitem] = useState({
      share_title: "",
      share_description: "",
      share_url: window.location.href,
      share_hashtag: "#SwopAnything",
  });
  const [wishlistitem, setWishlistitem] = useState({
    is_wishlisted: 0,
   
});
const [reportitem, setReportitem] = useState({
  is_reported: 0,
 
});
const [report_reasons, setReportreasons] = useState([]);
const [whatsappnumber, setWhatsAppNumber] = useState('');
  useEffect(() => {
    //  console.log("🚀 ~ file: ProductDetail.js:8 ~ ProductDetail ~ slug:", slug);
    dispatch(getProductDetail(slug)); // Dispatch your API call action here using slug
  }, [dispatch, slug]);

  const details = useSelector((state) => state.productDetail.details);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if(details && details.product_details){
    
      setShareitem({share_title:details.product_details.title, share_description:details.product_details.description, share_url:details.product_details.deep_url});
      setWishlistitem({is_wishlisted:details.product_details.is_wishlisted});
      // console.log('swwwwwwwwwwww',details.product_details.is_reported);
      setReportitem({is_reported:details.product_details.is_reported});
      setReportreasons(details.report_reasons);
      setPostedproductid(details.product_details.id);
      setWhatsAppNumber(details.product_details.posted_by_details.mobile_prefix_code+details.product_details.posted_by_details.mobileno);
    }
  }, [details]);

  const viewProfile = (e, user_slug) => {
    e.preventDefault();
    navigate("/profile/" + user_slug);
  };

  const viewchathistory = (e) => {
    e.preventDefault();
    navigate("/chat-history");
  };

  const viewUserlist = (e, user_slug) => {
  
    e.preventDefault();
    navigate("/userlisting/" + user_slug);
  };

  const addDefaultSrc = (ev) => {ev.target.src = "../../img/profile-img.png";}

  const openShareModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeShareModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

 
// Construct the WhatsApp link
const whatsappLink = "https://wa.me/" + whatsappnumber;

const wishlist = async (item) => {
  try {
      let action = !wishlistitem.is_wishlisted;
      const result = await dispatch(addwishlistItem({
          productId: item.id,
          action: action ? 1 : 0,
          type :'Product',
      }));
      setResult(result);
      // console.log('API Response:', result);

      // Check if the API call was successful
      if (result.payload.status === 200) {
          // Toggle active class
          const wishlistElement = document.querySelector('.wishlistpt');
          wishlistElement.classList.toggle('active');

          // Show/hide SVG based on the presence of active class
          const svgElement = wishlistElement.querySelector('svg');
          if (wishlistElement.classList.contains('active')) {
              svgElement.style.display = 'none';
          } else {
              svgElement.style.display = 'inline-block'; // Or any other appropriate display value
          }
      } else {
          // Handle unsuccessful API call
      }
  } catch (error) {
      // Handle any errors
      // console.error('Error adding item to wishlist:', error);
      // alert('An error occurred while adding item to wishlist.');
  }
};



  
 
  const isLoading = useSelector((state) => state.productDetail.isLoading);

  const openReportModal = () => {
    //e.preventDefault();
    if(reportitem.is_reported == 1){
      Swal.fire("Alert!", 'This product is already reported by you!', "Report");
      //document.getElementById('RequestCallback').classList.remove('open-it');
    } else {
    setSelectedreportreason('');
    setSelectedreportreasonother('');
    }
     
  };
  const closeReportModal = () => {
    // e.preventDefault();
    setreportModalOpened(false);
  };

  const activeProduct = async (e, product_details, suggest = 1) => {
    let profile_user_id = localStorage.getItem('user_id');
    try {
     
      const result = await dispatch(activeProductaction({
        profile_user_id: profile_user_id,
      }));
      // console.log('API Response:', result);
  
      // Check if the API call was successful
      if (result.payload.status === 200) {
        // Show success message
        setsuggestProducts(result.payload.data );
        // setsuggestModalOpened(true);
      } else {
      
      }
    } catch (error) {
      // Handle any errors
      // console.error('Error adding item to wishlist:', error);
      alert('An error occurred while adding item to wishlist.');
    }
  };
 

  const reportSwop = async (e) => {
    if (selected_report_reason == "") {
      Swal.fire({
        title: "Alert",
        text: "Please select a reason.",
        icon: "error",
      });
     return false;
    }

    if (
      selected_report_reason == "Other" &&
      selected_report_reason_other == ""
    ) {
      Swal.fire({
        title: "Alert",
        text: "Please enter other reason",
        icon: "error",
      });
     return false;
    }

    setreportModalOpened(false );
    try {
      
      const result = await dispatch(addReport({
        reason:
        selected_report_reason == "Other"
          ? selected_report_reason_other
          : selected_report_reason,
      posted_product_id: posted_product_id,
      type:'Product',
      }));
      setResult(result);
      // console.log('API Response:', result);
  
      // Check if the API call was successful
      if (result.payload && result.payload.status === 200) { 
           // Show success message
          Swal.fire("Success!", result.payload.message, "success");
          setReportitem({is_reported:1});
          document.getElementById('chooseanyonereport').classList.remove('open-it');
          document.body.classList.remove('hidden-scroll');
      } else {
        Swal.fire("Alert!", result.payload.message, "error");
       
      }
    } catch (error) {
      // Handle any errors
      // console.log('Error adding item to wishlist:', error);
      alert('An error occurred while adding item to wishlist.');
    }
  };

  useEffect(() => {
    if (result && result.payload && result.payload.status === 200) {
     
      dispatch(getProductDetail(slug));
      setSelectedreportreason('');
      setSelectedreportreasonother('');
    }
  }, [result]);

 
  // Custom arrow component for the previous button
const PrevArrow = ({ className, onClick }) => (
  <button type="button" className="prevarrow" onClick={onClick}><i><img src="../../img/toparrow.svg" alt=""/></i></button>
);

// Custom arrow component for the next button
const NextArrow = ({ className, onClick }) => (
  <button type="button" className="nextarrow" onClick={onClick}><i> <img src="../../img/bottomarrow.svg" alt=""/> </i></button>
);


 const [navSlider, setNavSlider] = useState(null); // State to hold reference to navigation slider
 const [mainSlideIndex, setMainSlideIndex] = useState(0);
 const [mainSlider, setMainSlider] = useState(null); // State to hold reference to main slider

 const slickSliderPrevSettings = {
   autoplay: false,
   vertical: true,
   infinite: false,
   slidesPerRow: 1,
   slidesToShow: 1,
   arrows: false,
   draggable: false,
   asNavFor: navSlider, // Connect to the navigation slider
   afterChange: setMainSlideIndex, // Update state on slide change
 };

 const slickSliderThumbSettings = {
   dots: false,
   infinite: false,
   slidesToShow: 3,
   slidesToScroll: 1,
   vertical: true,
   verticalSwiping: true,
   swipeToSlide: true,
   focusOnSelect: true,
   asNavFor: mainSlider, // Connect to the main slider
   ref: setNavSlider,
   prevArrow: <PrevArrow className="prevarrow" />,
   nextArrow: <NextArrow className="nextarrow" />,
 };

 const handleImageClick = (image) => {
  setCurrentImage(image);
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

 console.log('qqqqqqqqqqqqqqqqqqqqqqqq',localStorage.getItem("signInResponse"));
 console.log('qqqqqqqqqqqqqqqqqqqqqqqq',details.product_details);
  return (
    <main className="sa-main-wrap sa-trems-pages-wrap">
      <div className="container">
        <div className="breadcrumpart">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>Details</li>
          </ul>
        </div>
        <section className="details-wrap">
          <div className="row detailsrow">
            <div className="col-lg-7">
              <div className="module-gallery">
                <div className="slider-wrapper">
                  <div className="sldnavside">
                    {/* <button type="button" className="prevarrow">
                      <i>
                        <img src="../../img/toparrow.svg" alt="" />
                      </i>
                    </button> */}
                    <ul className="slider-thumb">
                      {/* {details &&
                        details.product_details &&
                        details.product_details.gallery.map((image, index) => (
                          <li className="type-image" key={index}>
                            <img src={image.media_url} alt="" />
                          </li>
                        ))} */}
                     
                        <Slider {...slickSliderThumbSettings} >
                          {details &&
                          details.product_details &&
                          details.product_details.gallery.map(image => {
                            return (
                                <li className="type-image" key={image}>
                                <img src={image.media_url} alt="" />
                                </li>
                            );
                          })}
                        </Slider>

                    </ul>
                    {/* <button type="button" className="nextarrow">
                      <i>
                        {" "}
                        <img src="../img/bottomarrow.svg" alt="" />{" "}
                      </i>
                    </button> */}
                  </div>
                  <div className="sldprevpt">
                    <ul className="slider-preview">
                      {/* {details &&
                        details.product_details &&
                        details.product_details.gallery.map((image, index) => (
                          <li className="type-image" key={index}>
                            <img src={image.media_url} alt="" />
                          </li>
                        ))} */}
                      
                      <Slider {...slickSliderPrevSettings} ref={setMainSlider}>
                        {details &&
                        details.product_details &&
                        details.product_details.gallery.map(slide => {
                            return (
                              <li key={slide} className="type-image" onClick={() => handleImageClick(slide.media_url)}>
                              <img src={slide.media_url} alt="" />
                              </li>
                            );
                        })}
                      </Slider>  

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="detailscontent">
                <div className="titlehead">
                  <h2>
                    {details &&
                      details.product_details &&
                      details.product_details.title}
                  </h2>
                  {localStorage.getItem("signInResponse") != null ?
               <a href="#" onClick={() => wishlist(details.product_details)} className={`wishlistpt ${details &&
                details.product_details &&
                details.product_details.is_wishlisted  ? "active" : ""}`}>
                {!details.product_details || !details.product_details.is_wishlisted ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                    >
                        <path
                            d="M22.089 12.7938L12.5106 23L2.93227 12.7938C2.30049 12.1323 1.80284 11.3373 1.47067 10.4588C1.1385 9.5803 0.979002 8.6373 1.00221 7.6892C1.02543 6.7411 1.23085 5.80844 1.60555 4.94996C1.98025 4.09147 2.51611 3.32575 3.17938 2.70102C3.84265 2.07629 4.61897 1.60608 5.45944 1.31999C6.29992 1.03391 7.18635 0.938157 8.06292 1.03876C8.93948 1.13936 9.78719 1.43414 10.5527 1.90454C11.3181 2.37493 11.9848 3.01075 12.5106 3.77196C13.0388 3.01628 13.7062 2.38601 14.4711 1.92061C15.2361 1.45521 16.0821 1.16469 16.9562 1.06723C17.8303 0.969769 18.7137 1.06747 19.5511 1.35422C20.3885 1.64097 21.1619 2.11059 21.8228 2.7337C22.4837 3.3568 23.018 4.11998 23.3922 4.97545C23.7664 5.83093 23.9724 6.76029 23.9974 7.70538C24.0224 8.65046 23.8658 9.59093 23.5374 10.4679C23.2091 11.3449 22.716 12.1395 22.089 12.802"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : null}
                <i className="icon-05 hart_icon" />  
            </a>
            
                  :
                  <a href="#"  data-popup-id="LogIn" className="wishlistpt open-popup" >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M22.089 12.7938L12.5106 23L2.93227 12.7938C2.30049 12.1323 1.80284 11.3373 1.47067 10.4588C1.1385 9.5803 0.979002 8.6373 1.00221 7.6892C1.02543 6.7411 1.23085 5.80844 1.60555 4.94996C1.98025 4.09147 2.51611 3.32575 3.17938 2.70102C3.84265 2.07629 4.61897 1.60608 5.45944 1.31999C6.29992 1.03391 7.18635 0.938157 8.06292 1.03876C8.93948 1.13936 9.78719 1.43414 10.5527 1.90454C11.3181 2.37493 11.9848 3.01075 12.5106 3.77196C13.0388 3.01628 13.7062 2.38601 14.4711 1.92061C15.2361 1.45521 16.0821 1.16469 16.9562 1.06723C17.8303 0.969769 18.7137 1.06747 19.5511 1.35422C20.3885 1.64097 21.1619 2.11059 21.8228 2.7337C22.4837 3.3568 23.018 4.11998 23.3922 4.97545C23.7664 5.83093 23.9724 6.76029 23.9974 7.70538C24.0224 8.65046 23.8658 9.59093 23.5374 10.4679C23.2091 11.3449 22.716 12.1395 22.089 12.802"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <i className="icon-05 hart_icon" />  
                </a>
                  }
                </div>
                <div className="listboxouter">
                  <ul className="listviewsrow">
                    <li>
                      <div className="cardbox">
                        <div className="iconimg">
                          <img src="../../img/locationicon.png" alt="" />
                        </div>
                        <div className="cardcontent">
                          <span>
                            {details &&
                              details.product_details &&
                              details.product_details.distance_text}
                          </span>
                          <p>
                            {details &&
                              details.product_details &&
                              details.product_details.city_name}
                          </p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="cardbox">
                        <div className="iconimg">
                          <img src="../../img/calendericon.png" alt="" />
                        </div>
                        <div className="cardcontent">
                          <span>
                            {" "}
                            {details &&
                              details.product_details &&
                              details.product_details.created_at}
                          </span>
                          <p>
                            {details &&
                              details.product_details &&
                              details.product_details.created_date}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cardbox">
                        <div className="iconimg">
                          <img src="../../img/calendericon.png" alt="" />
                        </div>
                        <div className="cardcontent">
                          <span>Total Views</span>
                          <p>
                            {details &&
                              details.product_details &&
                              details.product_details.total_views}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="swopper-outer">
                  <h2 className="smtitle">Swopper</h2>
                  <div className="swopperrow">
                    <div className="swopper-left">
                      <div className="swopperimg"
                          onClick={(e) =>
                            viewUserlist(
                              e,
                              details &&
                          details.product_details &&
                          details.product_details.posted_by_details &&
                          details.product_details.posted_by_details.id
                            )}
                            style={{cursor:'pointer'}}
                      >
                        {details &&
                        details.product_details &&
                        details.product_details.posted_by_details &&
                        details.product_details.posted_by_details.image ? (
                          <img
                          onError={addDefaultSrc}
                            src={
                              details.product_details.posted_by_details.image
                            }
                            alt=""
                          />
                        ) : (
                          <img src="../img/chat1.png" alt="" />
                        )}
                      </div>
                      <div className="swoppercontent">
                        <h2 className="swoppertitle"   onClick={(e) =>
                            viewUserlist(
                              e,
                              details &&
                          details.product_details &&
                          details.product_details.posted_by_details &&
                          details.product_details.posted_by_details.id
                            )} style={{cursor:'pointer'}}>
                          {details &&
                            details.product_details &&
                            details.product_details.posted_by_details &&
                            details.product_details.posted_by_details.name}
                        </h2>
                        <p>
                          {details &&
                            details.product_details &&
                            details.product_details.posted_by_details &&
                            details.product_details.posted_by_details
                              .reviews_rating &&
                            details.product_details.posted_by_details
                              .reviews_rating.total_reviews}{" "}
                          Reviews
                        </p>
                      </div>
                    </div>
                    <div className="swopper-right">
                      <div className="datetimept">
                        Member since{" "}
                        {details &&
                          details.product_details &&
                          details.product_details.posted_by_details &&
                          details.product_details.posted_by_details
                            .member_since}
                      </div>
                      <a href="#" 
                            onClick={(e) =>
                              viewProfile(
                                e,
                                details &&
                            details.product_details &&
                            details.product_details.posted_by_details &&
                            details.product_details.posted_by_details.slug
                              )
                            } className="swoppername">
                        View Profile <i className="icon-01"></i>
                      </a>
                    </div>
                  </div>
                  <div className="btn-row btn-bar-top-border btn-row-new">
                    <a href={whatsappLink} target="_blank" className="btn btn-whatsapp">
                    <img src="../../img/whatsappfixed-btn.svg" alt="whatsapp"/> Chat
                    </a>
                    {
                    localStorage.getItem("signInResponse") != null ?
                    details?.product_details?.offer_type === 2 
                    ? ''
                    : (
                    <a 
                    href="#" 
                    data-popup-id="chooseanyone" 
                    onClick={(e) => activeProduct(e, 1)} 
                    className="btn btn-border open-popup"
                    >
                    SUGGEST SWOP
                    </a>
                    )
                    : (
                    <a 
                    href="#" 
                    data-popup-id="LogIn" 
                    className="open-popup btn btn-border"
                    >
                    SUGGEST SWOP
                    </a>
                    )
                    }


                    { localStorage.getItem("signInResponse") != null ? <a href="avascript:void(0);" 
                    onClick={(e) => viewchathistory(e)} 
                    className="btn btn-primary">
                      START CHAT
                    </a>
                    :  <a href="#" 
                    //  onClick={(e) => suggestSwop(e, 0, details.product_details)}
                    data-popup-id="LogIn" className="open-popup btn btn-primary">
                      START CHAT
                    </a> }
                 
                  
                  </div>
                </div>

                <div className="swopper-outer description-outer">
                  <div className="headpanel">
                    <h2 className="smtitle">Description</h2>
                    <p>
                      {details &&
                        details.product_details &&
                        details.product_details.description}
                    </p>
                  </div>
                  {  details &&
                        details.product_details &&
                        details.product_details.remarks ?
                  <div className="headpanel">
                    <h2 className="smtitle">Condition/ Remark</h2>
                    <p>
                      {details &&
                        details.product_details &&
                        details.product_details.remarks}
                    </p>
                  </div> :''}

                  {details &&
                    details.product_details &&
                    details.product_details.offer_type !== 2 && (
                      <div className="boxlike">
                        <label>
                          I'd like to swop for{" "}
                          <span className="infowrap tooltinfo-main" data-tooltip="I'm looking for following swop">
                            <i className="incoico">
                              <img src="../img/info.svg" alt="" />
                            </i>
                          </span>
                        </label>
                        {details &&
                            details.product_details &&
                            details.product_details.desired_swops ?
                        <ul className="tagslist">
                          {details &&
                            details.product_details &&
                            details.product_details.desired_swops
                              .split(",")
                              .map((item, index) => (
                                <li key={index}>
                                  <div className="tagsbox"> {item.trim()}</div>
                                </li>
                              ))}
                        </ul> :''}
                      </div>
                    )}

                  {details &&
                    details.product_details &&
                    details.product_details.offer_type !== 1 && (
                      <div className="boxlike" style={{ marginTop: "1rem" }}>
                        <label>Price </label>
                        {details &&
                          details.product_details &&
                          details.product_details.price}{" "}
                        BWP
                      </div>
                    )}
                </div>

                

               <div className="repott-share-foot">
                     {localStorage.getItem("signInResponse") != null ? 
                     reportitem.is_reported == 1 ?
                     <a href="#" onClick={openReportModal} >
                     <i>
                     <img src="../img/heart.png" alt="" />
                     </i> Report this SWOP
                     </a>
                     :
                     <a href="#" data-popup-id="chooseanyonereport" onClick={openReportModal} className="open-popup">
                     <i>
                     <img src="../img/heart.png" alt="" />
                     </i> Report this SWOP
                     </a>
                     :
                     <a href="#" data-popup-id="LogIn" className="open-popup">
                     <i>
                     <img src="../img/heart.png" alt="" />
                     </i> Report this SWOP
                     </a>
                     }

                  <a href="javascript:void(0);" onClick={openShareModal}>
                    <i>
                      <img src="../img/shareclip.png" alt="" />
                    </i>{" "}
                    Share this SWOP
                  </a>
                          </div>
        <div className={`center-modal-default forgotmodel sharemodel ${
            isOpen ? "open-it" : "d-none"
          }`} >
          <div className="shadowpt" ></div>
          <div className="bottom-modal-content">
           
            <div className="modal-swop-header">
              <div className="modal-swop-title">Share this SWOP</div>
              {/* <div className="close-modal" >
              <span className="icon-10">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
            </div> */}
            <a href="javascript:void(0);" class="close_panel" onClick={closeShareModal}><i><img src={close} alt=""/></i></a>
            </div>


            <div className="default-modal-body">
              <FacebookShareButton
                url={shareitem.share_url}
                quote={shareitem.share_title}
                hashtag={shareitem.share_hashtag}
                className={""}
              >
                <FacebookIcon size={50} />
              </FacebookShareButton>

              <WhatsappShareButton
                url={shareitem.share_url}
                title={shareitem.share_title}
                className={""}
              >
                <WhatsappIcon size={50} />
              </WhatsappShareButton>

              <TwitterShareButton
                url={shareitem.share_url}
                title={shareitem.share_title}
                hashtags={shareitem.share_hashtag}
                className={""}
              >
                <TwitterIcon size={50} />
              </TwitterShareButton>

              <LinkedinShareButton
                url={shareitem.share_url}
                title={shareitem.share_title}
                summary={shareitem.share_hashtag}
                className={""}
              >
                <LinkedinIcon size={50} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>

{/*------------ Image popup--------------- */}

<div className={`center-modal-default forgotmodel sharemodel ${
            isModalOpen ? "open-it" : "d-none"
          }`} >
          <div className="shadowpt" onClick={closeModal}></div>
          <div className="bottom-modal-content" style={{maxWidth: '520px'}}>
           
            <div className="modal-swop-header previewehead">
              <a href="javascript:void(0);" class="close_panel" onClick={closeModal}><i><img src={close} alt=""/></i></a>
            </div>

            <div className="default-modal-body">
            <img src={currentImage} alt="" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
        </div>

{/*------------ Report popup--------------- */}
        <div className="comman-model comman-side-popup choose-any-model" id="chooseanyonereport">
       <div className="comman-popup-inner">
       <div className="top-model-header">
          <div className="pop-top-title">
             <h2>Report</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src={close} alt=""/></i></a>
       </div>
      
       <div className="popup-model-body"> 
       <div className="form-group">
        <label>Select Reason</label>
              <select
                  value={selected_report_reason}
                  onChange={(event) =>
                    setSelectedreportreason( event.target.value,
                    )
                  }
                  className="input-field form-control"
                >
                  <option value="">-- Select a Reason --</option>
                  {report_reasons.map((item, index) => {
                    return <option key={index}>{item}</option>;
                  })}
                </select> 
          </div>
          
          
          {selected_report_reason == "Other" ? ( 
                <div className="input-box form-group">
                   <label>Write Reason</label>
                  <input
                    type="text"
                    value={selected_report_reason_other}
                    onChange={(event) =>
                      setSelectedreportreasonother(event.target.value,
                      )
                    }
                    className="input-field form-control"
                  />
                </div>
              ) : (
                ""
              )}
          
           
       </div>
       <div className="model-footer-panel"> <button className="btn btn-primary" onClick={reportSwop}>Report</button></div>
    </div>
        <div className="shadowpt" ></div>
      </div>

{/*------------ Report popup--------------- */}
    
                
              </div>
            </div>
          </div>
        </section>

        <div className="sa-add-banner">
          <a href="#" className="sa-inside-add">
          <img 
            src={`${API_BASE_URL}/${details && details.product_banner}`}
            alt="add" />
          </a>
        </div>

        <section className="sa-comman-section">
          <div className="sa-heading-section">
            <h2 className="sa-title">
              Similar <span>Listings</span>
            </h2>
            {/* <a href="#" className="sa-view">View on Map <i><img src="../img/viewonmap.png" alt="map"/></i>  </a>  */}
            <div className="clear"></div>
          </div>

          <div className="sa-fpc-wrap">
            <div id="swopcafetown" className="default-carousel-simple">
              {details &&
                details.similar_items &&
                details.similar_items.map((card, index) => (
                  <Cards
                    key={index}
                    image={card.image}
                    title={card.title}
                    kmcontent={card.distance_text}
                    slug={card.slug}
                  />
                ))}
            </div>
          </div>
        </section>
      </div>
      {/* <SignIn />
      <SignUp /> */}
      <Swopsuggestion suggestProducts={suggestProducts} postedproductid={posted_product_id}/>
    
    </main>
  );
};

export default ProductDetail;
