import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../../img/logo-splash.png";
import addproduct from "../../img/plus-icon.png";
import bell from "../../img/bell.png";
import chat from "../../img/chat.png";
import flag from "../../img/flag.png";
import searchicon from "../../img/search.png";
import moremenu from "../../img/moremenu.png";
import itemsico from "../../img/items-ico.png";
import skillsico from "../../img/skills-ico.png";
import businessico from "../../img/banner-card-2.png";
import LocationModel from "../popups/LocationModel";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignIn/SignUp";
import ForgotPassword from "../SignIn/ForgotPassword";
import AddProductPage from "../products/AddProduct";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCategories, getSeach,getTrendingCategories } from "../../slices/headerSlice";
import { API_BASE_URL } from "../../utils/config";
import Loading from "./Loading";
import { isAuthenticated, getSignInResponse } from "../../utils/authUtil";
import OtpVerify from "../SignIn/OTPverify";
import Notifications from "../popups/Notifications";
import Categories from "../popups/Categories";
import UpdateMob from "../profile/UpdateMob";

function Header() {
  const signInResponse = getSignInResponse();
  const loggedIn = isAuthenticated();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getTrendingCategories());
  }, []);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setkeyword] = useState('');
  const [cursor, setCursor] = useState(-1);


  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeDiv = () => {
    setIsOpen(false);
  };
  const removeClass = () => {
    setMenuOpen(false);
  };

  const menuItems = [
    { label: "Automobile", icon: "../img/catimg01.jpg", link: "#" },
    { label: "Home", icon: "../img/catimg02.jpg", link: "#" },
    { label: "Electronics", icon: "../img/catimg03.jpg", link: "#" },
    { label: "Fashion", icon: "../img/catimg04.jpg", link: "#" },
    { label: "Kids & Babies", icon: "../img/catimg05.jpg", link: "#" },
    { label: "Office", icon: "../img/catimg06.jpg", link: "#" },
    { label: "Art & Designs", icon: "../img/catimg07.jpg", link: "#" },
    { label: "More", icon: moremenu, link: "#", className: "moreicons" },
  ];

  const globalLocation = useSelector((state) => state.location.globalLocation);

  const categories = useSelector((state) => state.header.categories);
  const results = useSelector((state) => state.header.search);

  const isLoading = useSelector((state) => state.header.isLoading);

  const [rootCategoryId, setRootCategoryId] = useState(1);
  const [roottype, setRootType] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // State to manage the index of the selected result
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };
  // Function to handle closing the dropdown
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Function to handle selecting a result from the dropdown
  const handleResultSelect = (index) => {
    setSelectedResultIndex(index);
    setIsDropdownOpen(false); // Close the dropdown after selecting a result
    navigateToSelectedResult(index);
  };

  // Function to navigate to the selected result page
  const navigateToSelectedResult = (index) => {
    if (index >= 0 && index < results.length) {
      closeDropdown();
      const { slug, type } = results[index];
      const url = `/detail/${slug}/${encodeURIComponent(type)}`;
      navigate(url);
    }
  };

  const onKeywordChange = (e) => {
    const value = e.target.value;
    setkeyword(value);
    dispatch(getSeach(value));
    openDropdown();
    // Reset selected result when keyword changes
  };




  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const location = useLocation(); // Use this hook to get the current location

  useEffect(() => {
    const checkIsMobileDevice = () => {
      setIsMobileDevice(window.innerWidth <= 768);
    };

    checkIsMobileDevice();
    window.addEventListener('resize', checkIsMobileDevice);

    return () => window.removeEventListener('resize', checkIsMobileDevice);
  }, []);

  // Reset dropdown open state on route change
  useEffect(() => {
    setDropdownOpen(false);
  }, [location.pathname]); // Effect runs when location.pathname changes

  const handleMouseEnterDropdown = () => {
    if (!isMobileDevice) {
      setDropdownOpen(true);
    }
  };

  const handleMouseLeaveDropdown = () => {
    if (!isMobileDevice) {
      setDropdownOpen(false);
    }
  };

  const handleClickDropdown = () => {
    if (isMobileDevice) {
      setDropdownOpen(!dropdownOpen);
    }
  };





  const handleKeyDown = (e) => {
    if (results.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedResultIndex((prevIndex) =>
          prevIndex < results.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedResultIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : results.length - 1
        );
        break;
      case "Enter":
        if (selectedResultIndex !== -1) {
          e.preventDefault();
          navigateToSelectedResult(selectedResultIndex);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedResultIndex, results]);

  const logout = () => {
    localStorage.removeItem("signInResponse");
    localStorage.removeItem("user_id");
    window.location.href = "/";
  };



  return (
    <>
      <header className="sa-header-container">
        <Loading loading={isLoading} />
        <div className="top-header">
          <div className="menuicon togglemenupt d-none" onClick={toggleMenu}>
            <img src="../../img/menuicon.svg" alt="" />
          </div>
          <Link to="/" className="logo">
            <img className="weblogo" src={logo} alt="logo" />
          </Link>
          <div className="searchwrap">
            <form>
              <div className="search-control">
                <div
                  className={`selectlaungage selectmodelclk ${isOpen ? "open-it" : ""
                    }`}
                  onClick={handleClick}
                >
                  <div className="selectinner">
                    <i className="flagicon">
                      <img src={flag} alt="flag" />
                    </i>
                    <span>{globalLocation && globalLocation.city}</span>
                    <span>En </span>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search....."
                  value={keyword}
                  onChange={onKeywordChange}
                />
                <button className="btn btn-primary" >
                  <i>
                    <img src={searchicon} alt="" />
                  </i>
                </button>
                {isDropdownOpen && (
                  <div className="result">
                    {results.map((item, index) => (
                      <p
                        key={item.slug}
                        className={index === selectedResultIndex ? "selected" : ""}
                        onClick={() => handleResultSelect(index)}
                      >
                        {item.title}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </form>

          </div>

          <div className="login-chat-cart">
            {loggedIn ? (
              <div className="wishlist-cart clarfix">
                <ul>
                  <li className="wishpt">
                    {" "}
                    <a
                      href="javascript:void(0);"
                      className="chatpt open-popup"
                      data-popup-id="Notifications"
                    >
                      <i>
                        <img src={bell} alt="" />
                      </i>
                    </a>
                  </li>
                   <li className="cartpt">
                    {" "}
                    <Link to={"/chat-history"} className="cart">
                      <i>
                        <img src={chat} alt="" />
                      </i>{" "}
                      {/* <span className="count">2</span> */}
                    </Link>{" "}
                  </li> 
                  <li className="businesspt">
                    {" "}
                    <a
                      href="/admin/business/login"
                      target="_blank"
                    >
                      <i>
                        {" "}
                        <img src={businessico} alt="" />{" "}
                      </i>
                      <span>Business Login</span>
                    </a>
                  </li>
                </ul>
              </div>
            ) :
              <div className="wishlist-cart clarfix">
                <ul>

                  <li className="businesspt">
                    {" "}
                    <a
                      href="/admin/business/login"
                      target="_blank"
                    >
                      <i>
                        {" "}
                        <img src={businessico} alt="" />{" "}
                      </i>
                      <span>Business Login</span>
                    </a>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="signreg clarfix">
              <a
                href=""
                className="login-reg sign_in_click"
                title="Login / Register"
              >
                {" "}
                <span>SignIn</span>{" "}
                <i>
                  <img className="usericon" src="../img/user.svg" alt="" />
                </i>
              </a>
            </div> */}
            <div
              className="signreg clarfix dropdown-parents"
              onMouseEnter={handleMouseEnterDropdown}
              onMouseLeave={handleMouseLeaveDropdown}
              onClick={handleClickDropdown}
            >
              {loggedIn ? (
                <Link
                  to={"/profile"}
                  // href="javascript:void(0);"
                  className={"login-reg"}
                  // className="login-reg open-popup"
                  data-popup-id="LogIn"
                  title={"View Profile"}
                // title="Login / Register"
                >
                  {" "}
                  <span className="mobhidept">{signInResponse.first_name}</span>{" "}
                  <i
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    {signInResponse.image && signInResponse.image.length > 0 ? (
                      <img
                        className="usericon"
                        src={`${API_BASE_URL}${signInResponse.image}`}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img className="usericon" src="../../img/user.svg" alt="" />
                    )}
                  </i>
                </Link>
              ) : (

                <a
                  href="javascript:void(0);"
                  className="login-reg open-popup"
                  data-popup-id="LogIn"
                  title="Login / Register"
                >
                  {" "}
                  <span>{"Sign In"}</span>{" "}
                  <i>
                    <img className="usericon" src="../../img/user.svg" alt="" />
                  </i>
                </a>
              )}
              {loggedIn && (
                <div className={`dropdown-web-menu ${dropdownOpen ? 'open' : ''}`}>
                  <ul>
                    <li>
                      <Link to="/update-profile">

                        <i>
                          {" "}
                          <img src="../../img/profile1.svg" alt="" />{" "}
                        </i>
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => logout()}>

                        <i>
                          {" "}
                          <img src={itemsico} alt="" />{" "}
                        </i>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}

            </div>
          </div>

        </div>

        {/* <div className="sellonbtn">
          <a href="">
            <img src={addproduct} className="pluseico" alt="Swop" />+ Add
            Product
          </a>
        </div> */}
   <div class="sellonbtn dropdown-parents">
   {loggedIn ? (
               <a href="javascript:void(0);" class="open-popup" data-popup-id="AddProduct"  onClick={() => {
                      setRootCategoryId(1);
                      setRootType('home');
                    }}>
               <img src={addproduct} class="pluseico" alt="Swop"/> <span class="mhide">+ Post an AD</span></a>
               ) : (
                <a  href="javascript:void(0);"
                className={"login-reg open-popup"}
                // className="login-reg open-popup"
                data-popup-id="LogIn"
                title={"Login / Register"}>
           <img src={addproduct} class="pluseico" alt="Swop"/> <span class="mhide">+ Post an AD</span></a>
                )}
               <div class="dropdown-web-menu d-none">
                  <ul>
                     <li>
                        <a href="javascript:void(0);" class="open-popup" data-popup-id="AddProduct">
                        <i> <img src="img/items-ico.png" alt=""/> </i>
                        <span>Items</span>
                        </a>
                     </li>
                     <li>
                        <a href="#">
                        <i> <img src="img/skills-ico.png" alt=""/> </i>
                        <span>Skills & Services</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>

   
        <nav className={`sa-menu ${isMenuOpen ? 'open-it' : ''}`}>
          <div className="container">
            <div className="mob-top-model-header">
              <div className="pop-top-title">
                <h2>Categories</h2>
              </div>
              <a href="javascript:void(0);" className="close_panel" onClick={toggleMenu}><i><img src="../../img/close.png" alt="" /></i></a>
            </div>
            <ul>
            {categories &&
  [...categories] // Create a shallow copy to avoid mutating the original array
    .sort((a, b) => a.order_number - b.order_number) // Sort by order_number
    .map((item, index) =>
    <li className="dropdown-parents" key={index} onClick={removeClass}>
      {item.slug === 'dealership' ? (
        <a
          href={`/listing/automobile/${item.slug}`}
          onClick={() => {
            localStorage.setItem("categoryname", item.name);
          }}
        >
          <i className="iconpt">
            <img src={`${API_BASE_URL}${item.icon}`} alt={item.name} />
          </i>
          {item.name}
        </a>
      ) : (
        <a
          href={`/listing/byCategory/${item.slug}`}
          onClick={() => {
            localStorage.setItem("categoryname", item.name);
          }}
        >
          <i className="iconpt">
            <img src={`${API_BASE_URL}${item.icon}`} alt={item.name} />
          </i>
          {item.name}
        </a>
      )}

      {item.subcategories && item.subcategories.length > 0 && (
        <div className="dropdown-web-menu">
          <ul>
            
             {item.subcategories &&
              [...item.subcategories]
            .sort((a, b) => a.order_number - b.order_number)
            .map((sub, subIndex) => (
              <li className="expanded-menu" key={subIndex}>
                {item.slug === 'dealership' ? (
                  <a href={`/listing/automobile/${sub.slug}`}
                  onClick={() => {
                    localStorage.setItem("categoryname", sub.name);
                  }}
                  >
                    {sub.name}
                  </a>
                ) : (
                  <a href={`/listing/byCategory/${sub.slug}`}
                  onClick={() => {
                    localStorage.setItem("categoryname", sub.name);
                  }}
                  >
                    {sub.name}
                  </a>
                )}

                {sub.subcategories && sub.subcategories.length > 0 && (
                  <div className="sub-expanded-menu">
                    <ul>
                      {sub.subcategories && [...sub.subcategories]
                      .sort((a, b) => a.order_number - b.order_number)
                      .map((subSub, subSubIndex) => (
                        <li key={subSubIndex}>
                          {item.slug === 'dealership' ? (
                            <a href={`/listing/automobile/${subSub.slug}`}
                            onClick={() => {
                              localStorage.setItem("categoryname", subSub.name);
                            }}>
                              {subSub.name}
                            </a>
                          ) : (
                            <a href={`/listing/byCategory/${subSub.slug}`}
                            onClick={() => {
                              localStorage.setItem("categoryname", subSub.name);
                            }}>
                              {subSub.name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="shadowbg"></div>
    </li>
  )}

              {/* <li>
                <a
                  href="javascript:void(0);"
                  className="moreicons open-popup"
                  data-popup-id="Categories"
                >
                  <i className="iconpt">
                    <img src={moremenu} alt="more" />{" "}
                  </i>
                  More
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header >
      <div className={`sa-countrymodal ${isOpen ? "open-it" : ""}`}>
        <LocationModel closeDiv={closeDiv} />
        <div className="shadowpt" onClick={closeDiv}></div>
      </div>
      <SignIn />
      <SignUp />
      <ForgotPassword />
      {roottype == 'home' ? <AddProductPage rootCategoryId={rootCategoryId} /> : ''}
      <OtpVerify />
      <Notifications />
      <Categories />
      <UpdateMob />
    </>
  );
}

export default Header;
